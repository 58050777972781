import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "swiper/css"
import "swiper/css/effect-fade"

const IndustryInsights = () => {

  return (
    <>
      <div className="flex-row flex-wrap justify-between flex lg:max-w-[61.563rem] m-auto">
        <ul className="flex flex-col lg:max-w-[calc(25%-1rem)] md:max-w-[calc(50%-0.625rem)] w-full">
          <li className="py-[0.625rem] h-1/2">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col bg-[#F8F7F3] rounded-[1.25rem] h-full">
              <StaticImage
                src="../assets/img/dice.webp"
                alt="Dice"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://insights.dice.com/2019/02/27/developers-tech-pros-judged-problem-solving-skills/"
                  rel="nofollow"
                  target="_blank"
                >
                  Developers, Tech Pros Judged More on Problem-Solving Skills
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/vb.jpeg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Vaibhav Shah
                </h3>
              </div>
            </div>
          </li>
          <li className="w-full py-[0.625rem] h-1/2">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col bg-[#F8F7F3] rounded-[1.25rem] h-full">
              <StaticImage
                src="../assets/img/e27.webp"
                alt="e27"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://e27.co/how-to-raise-funds-for-your-mobile-app-start-up-20191111/"
                  rel="nofollow"
                  target="_blank"
                >
                  How to raise funds for your mobile app startup?
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/vb.jpeg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Vaibhav Shah
                </h3>
              </div>
            </div>
          </li>
        </ul>
        <ul className="flex flex-col lg:max-w-[calc(25%-1rem)] md:max-w-[calc(50%-0.625rem)] w-full">
          <li className="py-[0.625rem] h-full">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col rounded-[1.25rem] h-full border-2 border-[#FFF2E3]">
              <StaticImage
                src="../assets/img/influencive.webp"
                alt="Influencive"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://www.influencive.com/animations-you-need-to-make-your-mobile-web-app-more-alluring-and-user-friendly/"
                  rel="nofollow"
                  target="_blank"
                >
                  Animations You Need To Make Your Mobile/Web App More Alluring
                  and User-Friendly
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/vb.jpeg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Vaibhav Shah
                </h3>
              </div>
            </div>
          </li>
        </ul>
        <ul className="flex flex-col lg:max-w-[calc(25%-1rem)] md:max-w-[calc(50%-0.625rem)] w-full">
          <li className="py-[0.625rem] h-1/2">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col border-2 border-[#FFF2E3] rounded-[1.25rem] h-full">
              <StaticImage
                src="../assets/img/g2crowd.webp"
                alt="g2crowd"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://learn.g2crowd.com/app-marketing"
                  rel="nofollow"
                  target="_blank"
                >
                  7 Best App Marketing Tactics from the Pros
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/user.jpeg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Nilesh Kadivar
                </h3>
              </div>
            </div>
          </li>
          <li className="w-full py-[0.625rem] h-1/2">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col bg-[#F8F7F3] rounded-[1.25rem] h-full">
              <StaticImage
                src="../assets/img/upw.webp"
                alt="Upwork"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                {/* <a
                  href="https://www.upwork.com/hiring/for-clients/how-to-boost-conversion-rate-with-web-design/"
                  rel="nofollow"
                  target="_blank"
                > */}
                How to Boost Conversion Rates with Basic Web Design Principles
                {/* </a> */}
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/goldey.jpg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Goldy Benedict
                </h3>
              </div>
            </div>
          </li>
        </ul>
        <ul className="flex flex-col lg:max-w-[calc(25%-1rem)] md:max-w-[calc(50%-0.625rem)] w-full">
          <li className="py-[0.625rem]">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col border-2 border-[#FFF2E3] rounded-[1.25rem]">
              <StaticImage
                src="../assets/img/startup.webp"
                alt="The Startup publisher"
                placeholder="blurred"
                className="max-w-[130px]"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://medium.com/swlh/10-free-tools-you-need-to-make-your-website-more-effective-294ca327c7"
                  rel="nofollow"
                  target="_blank"
                >
                  10 Free Tools You Need to Make Your Website More Effective
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/goldey.jpg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Goldy Benedict
                </h3>
              </div>
            </div>
          </li>
          <li className="w-full py-[0.625rem]">
            <div className="px-[1.75rem] py-[1.875rem] flex items-start justify-center flex-col border-2 border-[#FFF2E3] rounded-[1.25rem]">
              <StaticImage
                src="../assets/img/databox.webp"
                alt="databox"
                placeholder="blurred"
              />
              <p className="text-[0.875rem] pb-3 pt-[1.875rem] leading-[1.625rem] text-black">
                <a
                  href="https://databox.com/blog-seo"
                  rel="nofollow"
                  target="_blank"
                >
                  Adding new data & trends is a great way to freshen up an old blog post.
                </a>
              </p>
              <div className="flex items-center auther">
                <StaticImage
                  src="../assets/img/user.jpeg"
                  alt="Author"
                  className="w-[2.5rem] h-[2.5rem] rounded-full"
                  placeholder="blurred"
                />
                <h3 className="text-[0.875rem] font-blancotrialbold leading-[3rem] text-center pl-[0.625rem]">
                  Nilesh Kadivar
                </h3>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default IndustryInsights
